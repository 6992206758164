body {
  background: #efe0ff;
  margin: 0;
  padding: 0;
}

.backdrop {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@-webkit-keyframes cloudRail {
  100% {
    margin-left: -1000px;
  }
  0% {
    margin-left: 100%;
  }
}

@-moz-keyframes cloudRail {
  100% {
    margin-left: -1000px;
  }
  0% {
    margin-left: 100%;
  }
}

@keyframes cloudRail {
  100% {
    margin-left: -1000px;
  }
  0% {
    margin-left: 100%;
  }
}

.x1 {
  -webkit-animation: cloudRail 35s linear infinite;
  -moz-animation: cloudRail 35s linear infinite;
  animation: cloudRail 35s linear infinite;
}

.x2 {
  -webkit-animation: cloudRail 20s linear infinite;
  -moz-animation: cloudRail 20s linear infinite;
  animation: cloudRail 20s linear infinite;
}

.x3 {
  -webkit-animation: cloudRail 30s linear infinite;
  -moz-animation: cloudRail 30s linear infinite;
  animation: cloudRail 30s linear infinite;
}

.x4 {
  -webkit-animation: cloudRail 18s linear infinite;
  -moz-animation: cloudRail 18s linear infinite;
  animation: cloudRail 18s linear infinite;
}

.x5 {
  -webkit-animation: cloudRail 25s linear infinite;
  -moz-animation: cloudRail 25s linear infinite;
  animation: cloudRail 25s linear infinite;
}

.mw-Tiny {
  transform: scale(1.35);
  margin-bottom: 55%;
}

.background-wrap {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.clicky {
  box-shadow: 0 5px 0 #7843a8;

  &:hover {
    box-shadow: none;
    transform: translateY(5px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.very-clicky {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 5px, rgba(0, 0, 0, 0.3) 0px 10px, rgba(0, 0, 0, 0.2) 0px 15px,
    rgba(0, 0, 0, 0.1) 0px 20px, rgba(0, 0, 0, 0.05) 0px 25px;

  &:hover {
    box-shadow: none;
    transform: translateY(10px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(10px);
  }
}

.gradient-border {
  --borderWidth: 12px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);

  &::after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

.gradient-background {
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dropShadow {
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: rgba(181, 99, 255, 0.4) 5px 5px, rgba(181, 99, 255, 0.3) 10px 10px, rgba(181, 99, 255, 0.2) 15px 15px,
    rgba(181, 99, 255, 0.1) 20px 20px, rgba(181, 99, 255, 0.05) 25px 25px;
}

.avatarShadow {
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.strokedText {
  -webkit-text-stroke: 3px black;
}

.chat-bubble {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: #fff;
  padding: 0.5em 1em;
  position: relative;
  border-radius: 0 0.25em 0.25em 0.25em;
}

.fourK-video {
  -webkit-clip-path: inset(0 0 115px 0);
  clip-path: inset(0 0 115px 0);
}
