@import url("https://use.typekit.net/haj7mno.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: roc-grotesk-wide, sans-serif;
    font-weight: 200;
    font-style: normal;
  }
}
